import React, { useEffect, useState } from 'react'
import './Recipe.css'
import axios from 'axios'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Redirect } from 'react-router-dom'
import { store } from '../redux/store'
import { storeOneRecipeAction } from '../redux/actions'

const Recipe = () => {

    const [ goToShowOneRecipe, setGoToShowOneRecipe ] = useState(false)
    const [ recipes, setRecipes ] = useState([])

    useEffect( () => {
        axios.get('http://localhost:3002/Recipes')
            .then( res => res.data )
            .then( data => {
                setRecipes(data)
            })
    }, [] )

    const handleClick = (selectedRecipe) => {
        store.dispatch( storeOneRecipeAction( selectedRecipe ) )
        setGoToShowOneRecipe(true)
    }

    const recipesToDisplay = recipes.map( recipe => {
        return(<Typography key={recipe._id} id={recipe._id} variant='h5' className='recipeTitle' >
                    <Button onClick={ () => handleClick( recipe ) }>
                        {recipe.title} by {recipe.author}
                    </Button>
               </Typography>)
    } )

    return (
        <div className='Recipe' >
            {goToShowOneRecipe ? <Redirect to='/ShowOneRecipe' /> : null}
            { recipesToDisplay }
        </div>
    )
}

export default Recipe

/* 
 * Child of the Recipe Page
 */

import React, { useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ShowRecipe from '../Components/ShowRecipe'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Typography from '@material-ui/core/Typography'

    const AddRecipe = () => {

    const [ amount, setAmount ] = useState('')
    const [ author, setAuthor ] = useState('')
    const [ description, setDescription ] = useState('')
    const [ ingredient, setIngredient ] = useState('')
    const [ ingredientArray, setIngredientArray ] = useState([])
    const [ measure, setMeasure ] = useState('')
    const [ recipePhotoUrl, setRecipePhotoUrl ] = useState('')
    const [ recipeUrl, setRecipeUrl ] = useState('')
    const [ servings, setServings ] = useState('')
    const [ step, setStep ] = useState([])
    const [ stepArray, setStepArray ] = useState([])
    const [ time, setTime ] = useState('')
    const [ title, setTitle ] = useState('')

        const handleAddIngredient = () => {
        var tempIngredientArray = ingredientArray
        tempIngredientArray.push({ ingredientName: ingredient, ingredientAmount: amount, ingredientMeasure: measure })

        setIngredientArray( tempIngredientArray )
        setIngredient('')
        setAmount('')
        setMeasure('')
    }

    const handleAddStep = () => {
        var tempStepArray = stepArray
        tempStepArray.push({ stepNumber: stepArray.length + 1, stepInstructions: step })

        setStepArray( tempStepArray )
        setStep('')
    }

    const handleAmount = (e) => {
        setAmount(e.target.value)
    }

    const handleAuthor = (e) => {
        setAuthor(e.target.value)
    }

    const handleDescription = (e) => {
        setDescription(e.target.value)
    }

    const handleIngredient = (e) => {
        setIngredient(e.target.value)
    }

    const handleMeasure = (e) => {
        setMeasure(e.target.value)
    }

    const handleRecipePhotoUrl = (e) => {
        setRecipePhotoUrl(e.target.value)
    }

    const handleRecipeUrl = (e) => {
        setRecipeUrl(e.target.value)
    }

    const handleServings = (e) => {
        setServings(e.target.value)
    }

    const handleStep = (e) => {
        setStep(e.target.value)
    }

    const handleSubmit = () => {
        axios.post('http://localhost:3002/AddRecipe', {
            author: author,
            description: description,
            ingredientArray: ingredientArray,
            recipePhotoUrl: recipePhotoUrl,
            recipeUrl: recipeUrl,
            servings: servings,
            stepArray: stepArray,
            time: time,
            title: title
        })
            .then( res => res.data )
            .then( data => {
            } )
    }

    const handleTime = (e) => {
        setTime(e.target.value)
    }

    const handleTitle = (e) => {
        setTitle(e.target.value)
    }

    return (
        <Box >
            <Container>

                <Grid container >
                    <Typography variant='h4' style={{marginTop: '2rem', textAlign: 'center', width: '100%'}} >
                        Add a Recipe
                    </Typography>
                </Grid>

                <Grid container >
                    <TextField
                        label='Recipe Title'
                        onChange={handleTitle}
                        style={{marginTop: '2rem', width: '100%'}}
                        variant='outlined'
                    />
                </Grid>

                <Grid container >
                    <TextField
                        label='Recipe Url'
                        onChange={handleRecipeUrl}
                        style={{marginTop: '1rem', width: '100%'}}
                        variant='outlined'
                    />
                </Grid>

                <Grid container justify='space-between'>
                <TextField
                        label='Author'
                        onChange={handleAuthor}
                        style={{marginTop: '1rem', width: '30%'}}
                        variant='outlined'
                    />
                <TextField
                        label='Servings'
                        onChange={handleServings}
                        style={{marginTop: '1rem', width: '30%'}}
                        variant='outlined'
                    />
                <TextField
                        label='Time'
                        onChange={handleTime}
                        style={{marginTop: '1rem', width: '30%'}}
                        variant='outlined'
                    />
                </Grid>

                <Grid container >
                    <TextareaAutosize
                        placeholder='Recipe Description'
                        onChange={handleDescription}
                        rowsMin={5}
                        style={{marginTop: '1rem', padding: '0.5rem', width: '100%'}}
                        variant='outlined'
                    />
                </Grid>

                <Grid container >
                    <TextField
                        label='Recipe Photo Url'
                        onChange={handleRecipePhotoUrl}
                        style={{marginTop: '1rem', width: '100%'}}
                        variant='outlined'
                    />
                </Grid>

                <Grid container justify='space-between'>
                    <TextField
                            label='Amount'
                            onChange={handleAmount}
                            style={{marginTop: '1rem', width: '30%'}}
                            variant='outlined'
                            value={amount}
                        />
                    <FormControl
                            style={{marginTop: '1rem', width: '15%'}}
                            variant='outlined'
                    >
                        <InputLabel>Measure</InputLabel>
                        <Select
                            label='Measure'
                            onChange={handleMeasure}
                            value={measure}
                        >
                            <MenuItem value={''}>None</MenuItem>
                            <MenuItem value={'cup'}>Cup</MenuItem>
                            <MenuItem value={'lbs'}>Lbs</MenuItem>
                            <MenuItem value={'ounces'}>Ounces</MenuItem>
                            <MenuItem value={'tbsp'}>TBSP</MenuItem>
                            <MenuItem value={'tsp'}>tsp</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                            label='Ingredient'
                            onChange={handleIngredient}
                            style={{marginTop: '1rem', width: '30%'}}
                            variant='outlined'
                            value={ingredient}
                        />
                    <Button variant="contained" color="primary" style={{marginTop: '1rem', width: '10%'}} onClick={handleAddIngredient} >
                        Add
                    </Button>
                </Grid>

                <Grid container justify='space-between' alignItems='center'>
                    <TextareaAutosize
                        placeholder='Preparation Step'
                        onChange={handleStep}
                        rowsMin={5}
                        style={{marginTop: '1rem', padding: '0.5rem', width: '85%'}}
                        value={step}
                        variant='outlined'
                    />
                    <Button variant="contained" color="primary" style={{marginTop: '1rem', width: '10%', height: '56px'}} onClick={handleAddStep} >
                        Add
                    </Button>
                </Grid>

                <Grid container justify='space-between' alignItems='center'>
                    <Button variant="contained" color="primary" style={{marginTop: '1rem', width: '15%', height: '56px'}} onClick={handleSubmit} >
                        Submit Recipe
                    </Button>
                </Grid>

            </Container>
            {title.length > 0 ?
                <ShowRecipe
                    author={author}
                    description={description}
                    ingredientArray={ingredientArray}
                    recipePhotoUrl={recipePhotoUrl}
                    stepArray={stepArray}
                    time={time}
                    title={title}
                    servings={servings}
                />
            : null}
        </Box>
    )
}

export default AddRecipe

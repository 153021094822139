import React from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom'
import AddRecipe from './Pages/AddRecipe'
import Landing from './Pages/Landing'
import Recipe from './Pages/Recipe'
import ShowOneRecipe from './Pages/ShowOneRecipe'
import Travel from './Pages/Travel'

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path='/'>
          <Landing />
        </Route>
        <Route exact path='/AddRecipe'>
          <AddRecipe />
        </Route>
        <Route exact path='/Landing' >
          <Landing />
        </Route>
        <Route exact path='/Recipe' >
          <Recipe />
        </Route>
        <Route exact path='/ShowOneRecipe'>
          <ShowOneRecipe />
        </Route>
        <Route exact path='/Travel' >
          <Travel />
        </Route>
      </Switch>
    </div>
  );
}

export default App;

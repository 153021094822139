import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { Link } from 'react-router-dom'
import './Landing.css'

const Landing = props => {
    return (
        <Box className='Landing' >
            <Container >
                <Grid container direction='row' justify='center' >

                    <Grid item xs={2} style={{marginRight: '3rem', marginTop: '5rem'}} >
                        <Link to='/Recipe' style={{textDecoration: 'none'}} >
                            <Button  >
                                <Paper style={{backgroundColor: 'transparent', width: '250px', border: '1px solid rgba(211,211,211,1.0)'}} >
                                    <img
                                        style={{width: '80%', borderRadius: '0.5rem', marginTop: '1.5rem'}}
                                        alt='recipe'
                                        src='https://images.unsplash.com/photo-1512010151537-2cf5c638ad51?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3634&q=80'
                                    />
                                    <Typography variant='h5' style={{margin: '1rem'}} >
                                            Recipe
                                    </Typography>
                                </Paper>
                            </Button>
                        </Link>
                    </Grid>

                    <Grid item xs={2} style={{marginLeft: '3rem', marginTop: '5rem'}} >
                        <Link to='/Travel' style={{textDecoration: 'none'}} >
                            <Button  >
                                <Paper style={{backgroundColor: 'transparent', width: '250px', border: '1px solid rgba(211,211,211,1.0)'}} >
                                    <img
                                        style={{width: '80%', borderRadius: '0.5rem', marginTop: '1.5rem'}}
                                        alt='travel'
                                        src='https://images.unsplash.com/photo-1476900543704-4312b78632f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3634&q=80'
                                    />
                                    <Typography variant='h5' style={{margin: '1rem'}} >
                                            Travel
                                    </Typography>
                                </Paper>
                            </Button>
                        </Link>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}

export default Landing

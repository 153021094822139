import React from 'react'
import { store } from '../redux/store'

const ShowOneRecipe = () => {

    const { currentRecipe } = store.getState()

    console.log('ShowOneRecipe Page recie is ', currentRecipe)

    return (
        <div>
            
        </div>
    )
}

export default ShowOneRecipe

/* 
 * Child of the AddRecipe Page
 * Data is passed to this Component in Props
 */

import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const ShowRecipe = ({ author,description, ingredientArray, recipePhotoUrl, stepArray, time, title, servings }) => {

    var ingredientsToDisplay = []
    var stepsToDisplay = []


    if( ingredientArray !== undefined ){
        ingredientsToDisplay = ingredientArray.map( (ingredient, i) => {
            return(<Typography key={i} variant='h6' style={{marginLeft: '5rem', marginBottom: '1rem', textAlign: 'left'}} >
                        {ingredient.ingredientAmount} {ingredient.ingredientMeasure} { ingredient.ingredientName }
                   </Typography>)
        } )
    }

    if( stepArray !== undefined ){
        stepsToDisplay = stepArray.map( (step, i) => {
            return(<Typography key={i} variant='h6' style={{marginBottom: '2rem', textAlign: 'left'}} >
                        <strong>Step {step.stepNumber}</strong> { step.stepInstructions }
                   </Typography>)
        } )
    }

    return (
        <Box style={{marginTop: '2rem', marginBottom: '2rem'}}>
            <Container>

                <Grid container>
                    <Typography variant='h4'>
                        { title }
                    </Typography>
                </Grid>

                {author.length > 0 ?
                <Grid container>
                    <Typography variant='h6' style={{marginTop: '1rem'}}>
                        by: { author }
                    </Typography>
                </Grid>
                : null}

                {author.length > 0 && title.length > 0 ?
                <hr style={{marginTop: '1rem'}}/>
                : null}

                {servings.length > 0 ?
                    <Grid container>
                        <Typography variant='h6' style={{marginTop: '1rem'}}>
                            <strong>Yield</strong> { servings }
                        </Typography>
                    </Grid>
                : null}

                {time.length > 0 ?
                    <Grid container>
                        <Typography variant='h6' style={{marginTop: '1rem'}}>
                            <strong>Time</strong> { time }
                        </Typography>
                    </Grid>
                : null}

                <Grid container justify='space-between' style={{marginTop: '1rem'}}>
                    <Grid item xs={5}>
                        <Typography variant='h6' style={{textAlign: 'left'}}>
                            { description }
                        </Typography>
                    </Grid>
                    {recipePhotoUrl.length > 0 ?
                        <Grid item xs={6}>
                        <img
                            style={{width: '100%'}}
                            alt='recipe'
                            src={recipePhotoUrl}
                        />
                        </Grid>
                    : null}
                </Grid>

                {ingredientArray.length > 0 || stepArray.length > 0 ?
                <hr style={{marginTop: '1rem'}}/>
                : null}

                
                    <Grid container justify='space-between' style={{marginTop: '1rem'}}>
                    {ingredientArray.length > 0 ?
                        <Grid item xs={5}>
                            <Typography variant='h6' style={{marginBottom: '2rem', textAlign: 'left'}} >
                                <strong>INGREDIENTS</strong>
                            </Typography>
                            { ingredientsToDisplay }
                        </Grid>
                    : null}
                    {stepArray.length > 0 ?
                        <Grid item xs={6}>
                        <Typography variant='h6' style={{marginBottom: '2rem', textAlign: 'left'}} >
                                <strong>PREPARATION</strong>
                            </Typography>
                            { stepsToDisplay }
                        </Grid>
                    : null}
                    </Grid>
                

            </Container>
        </Box>
    )
}

ShowRecipe.propTypes = {
    author: PropTypes.string,
    description: PropTypes.string,
    ingredientArray: PropTypes.array,
    recipePhotoUrl: PropTypes.string,
    stepArray: PropTypes.array,
    time: PropTypes.string,
    title: PropTypes.string,
    servings: PropTypes.string
}

export default ShowRecipe

export default (state, action) => {

    switch(action.type){

      case "STORE_ONE_RECIPE":
        //  console.log('STORE_ONE_RECIPE Reducer is ', action.currentRecipe)
         return {
            ...state,
            currentRecipe: action.currentRecipe
         }


      default:
      return state
    }
 }
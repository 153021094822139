import React from 'react'
import './Travel.css'

const Travel = () => {
    return (
        <div
            className='Travel'
            style={{backgroundImage: "url('https://images.unsplash.com/photo-1470790376778-a9fbc86d70e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1849&q=80')"}}
        >
            {/* Travel Planning: https://images.unsplash.com/photo-1488646953014-85cb44e25828?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1833&q=80 */}
        </div>
    )
}

export default Travel
